<template lang="html">
  <div class="verification">
    <div class="desc">
      <p>更换手机号后，下次登录可使用新手机号登录</p>
      <p>当前手机号<span>18523845367</span></p>
    </div>
    <div class="box">
      <div class="list">
        <div class="left">
          <div class="">
            <img src="../../assets/image/phone.png" alt="">
            <span>+86</span>
          </div>
          <div class="text">
            中国大陆
          </div>
        </div>
        <div class="right">
          <van-field clearable placeholder="请输入你本人手机号码" />
        </div>
      </div>
      <div class="btn">
        <van-button type="primary" round @click="show = true">下一步</van-button>
      </div>
    </div>
    <van-popup v-model:show="show" round>
      <div class="model">
        <div class="title">
          验证码
        </div>
        <div class="content">
          <div class="list">
            <van-field input-align="center" maxlength="1" type="digit" />
          </div>
          <div class="list">
            <van-field input-align="center" maxlength="1" type="digit" />
          </div>
          <div class="list">
            <van-field input-align="center" maxlength="1" type="digit" />
          </div>
          <div class="list">
            <van-field input-align="center" maxlength="1" type="digit" />
          </div>
        </div>
        <div class="btns">
          <div class="btn">
            <van-button type="primary" color="#F8F8F8" round>
              <span style="color:#B9B9B9;">取消</span>
            </van-button>
          </div>
          <div class="btn">
            <van-button type="primary" round>确定</van-button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    name: 'verification',
    data() {
      return {
        show: false
      };
    }
  };
</script>

<style lang="scss" scoped>
  .verification {
    padding: 0 24px;
    .desc {
      text-align: center;
      margin-top: 70px;
      p {
        font-size: 28px;
        color: #333333;
        span {
          color: #529100;
        }
      }
    }
    .box {
      box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
      border-radius: 30px;
      margin-top: 80px;
      padding-bottom: 80px;
      .list {
        display: flex;
        justify-content: center;
        padding-top: 80px;
        .left {
          margin-right: 60px;
          img {
            width: 48px;
            height: 48px;
          }
          span {
            font-size: 36px;
            font-weight: bold;
            color: #000000;
            margin-left: 20px;
          }
          .text {
            font-size: 24px;
            color: #333333;
            text-align: right;
            margin-top: 40px;
          }
        }
        .right {
          .van-field {
            padding: 0;
            margin-top: 10px;
          }
        }
      }
      .btn {
        width: 468px;
        height: 96px;
        margin: 120px auto 0 auto;
      }
    }
    .model {
      width: 562px;
      height: 480px;
      background: #FFFFFF;
      border-radius: 30px;
      padding: 0 70px;
      .title {
        text-align: center;
        padding-top: 60px;
        font-size: 32px;
        color: #000000;
      }
      .content {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        .list {
          border-bottom: 2px solid #D2D2D2;
          margin-right: 30px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .btns {
        display: flex;
        margin-top: 90px;
        .btn {
          width: 250px;
          height: 96px;
          margin-right: 60px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
</style>
